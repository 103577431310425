import { render, staticRenderFns } from "./default.vue?vue&type=template&id=ebc4de02&scoped=true"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"
import style0 from "./default.vue?vue&type=style&index=0&id=ebc4de02&prod&lang=scss&scoped=true"
import style1 from "./default.vue?vue&type=style&index=1&id=ebc4de02&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ebc4de02",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ApplinksPopup: require('/app/src/components/ApplinksPopup.vue').default})
