import { parseSelection } from '~/fc/advancedOptionHelper'
import Job from '~/fc/Converter/job'
import { progressBarClass, truncate, uuidv4 } from '~/fc/helpers'

export default class BulkConverter {
    constructor(ctx) {
        this.context = ctx;
        this.auth = this.context.$auth;
        this.store = $nuxt.$store;
        this.info = this.store.state.info;
        this.slug = ctx.slug
        this.rotationOptionId = ctx.rotationOptionId
        this.topMarginOptionId = 'image_top_margin'
        this.leftMarginOptionId = 'image_left_margin'
        this.autoSaveToDrive = this.store.state.autoSaveToDrive
        this.list = $nuxt.$store.state.list

        if (this.store.state.advancedSetting.merging) {
            this.target = this.store.state.advancedSetting.merging.split('-')[this.store.state.advancedSetting.merging.split('-').length - 1]
        } else if (this.store.state.advancedSetting.defaultTarget) {
            this.target = this.store.state.advancedSetting.defaultTarget
        }
    }

    /**
     * 
     * @param {*} callback - callback function after job starts 
     */
    async process(callback, getTasks = false) {
        const { advancedSetting } = this.store.state

        const list =
            this.slug &&
                this.store.state.advancedSetting.perConversionType[this.slug] &&
                this.store.state.advancedSetting.perConversionType[this.slug].list
                ? this.store.state.advancedSetting.perConversionType[this.slug].list
                : []
        const options = parseSelection(
            list,
            advancedSetting.userData.allSelection.root,
            getTasks,
            true
        )

        const target = this.target ? this.target.toLowerCase() : ''
        const importTasks = {}
        const convertTasks = {}
        const uploadTasks = {}
        this.store.state.list.forEach((importTask, i) => {
            const selections = this.store.state.advancedSetting.userData.allSelection[importTask.id]

            if(importTask.import) {
            } else if (importTask.file === 'url') {
                if (importTask.gtoken) {
                    importTasks['import-' + i] = {
                        operation: 'import/google-drive',
                        fileId: importTask.url,
                        gtoken: importTask.gtoken,
                        filename: importTask.name,
                        filesize: importTask.total
                    }
                } else {
                    if (importTask.name) {
                        importTasks['import-' + i] = {
                            operation: 'import/url',
                            url: importTask.url,
                            filename: importTask.name
                        }
                    } else {
                        importTasks['import-' + i] = {
                            operation: 'import/url',
                            url: importTask.url
                        }
                    }
                }
            } else {
                importTasks['import-' + i] = {
                    operation: 'import/upload'
                }
                uploadTasks['import-' + i] = importTask
            }
            if (
                selections && selections[this.rotationOptionId] != undefined && selections[this.rotationOptionId] != '0' ||
                selections && selections[this.topMarginOptionId] != undefined && selections[this.topMarginOptionId] != '0' ||
                selections && selections[this.leftMarginOptionId] != undefined && selections[this.leftMarginOptionId] != '0'
            ) {
                convertTasks['convert-' + i] = {
                    operation: 'convert',
                    input: importTask.import || 'import-' + i,
                    output_format: importTask.ext,
                    options: parseSelection(
                        list,
                        selections,
                        getTasks,
                        true
                    )
                }
            }
        })

        const exportTasks = this.getExportTasks()
        const mergeInputs = Object.keys(importTasks).map((key, index) => (convertTasks.hasOwnProperty('convert-' + index) ? ('convert-' + index) : key))
        const reloadedImports = this.store.state.list.filter(file => file.import).map(file => file.import)

        const tasks = {
            ...importTasks,
            ...convertTasks,
            merge: {
                operation: 'merge',
                input: [...mergeInputs, ...reloadedImports],
                output_format: target,
                options
            },
            ...exportTasks
        }
        
        if(getTasks) {
            const _tasks = {...tasks}
            _tasks.merge.input_format = this.store.state.list[0].ext
            return _tasks
        }

        const job = new Job(tasks)

        await job.processBulkFiles({
            uploads: uploadTasks,
            jobId: uuidv4(),
        })

        callback()
    }

    /**
     * This method generates the export tasks needed for the job
     * @returns exportTasks
     */
    getExportTasks() {
        const exportTasks = {
            'export-url': {
                operation: "export/url",
                input: 'merge',
            }
        }
        if (this.autoSaveToDrive) exportTasks['export-google-drive'] = {
            operation: "export/google-drive",
            input: 'merge',
            token: $nuxt.$store.state.store.googleDriveTokenForUpload,
            folder: this.list[0].folder || 'root'
        }
        return exportTasks
    }
}

