import { formatSize } from "~/fc/File/FileHelpers";
import { sentTelemetryEvent } from '~/fc/TelemetryEvents'

class UserPrivilegesValidator {
    constructor(ctx) {
        this.context = ctx;
        this.router = this.context.$router;
        this.store = this.context.$store;
        this.localePath = this.context.localePath;
        this.info = this.store.state.info;
        this.file = null
    }

    setFile(file) {
        this.file = file
        return this
    }

    getUploadMaxLimit() {
        const UploadMaxLimit = $nuxt.$store.state.subscription.plan.maxFileSizeInGb + 'GB'
        return UploadMaxLimit.includes('GB')
            ? parseFloat(UploadMaxLimit.replace('GB', '')) * 1024 * 1024 * 1024
            : parseFloat(UploadMaxLimit.replace('MB', '')) * 1024 * 1024
    }

    handleMaxFileSizeExceeded(e) {
        if (this.context.$route.path == '/pricing') return

        const accountType = this.context.$auth.loggedIn
            ? this.store.state.subscription.name
            : "Guest";

        const uploadMaxLimit = $nuxt.$store.state.subscription.plan.maxFileSizeInGb + "GB";

        const accountMaxFileLimit = $nuxt
            .$t('account_max_file_size_limit')
            .replace('ACCOUNT_TYPE', accountType)
            .replace('MAX_LIMIT', uploadMaxLimit);

        let msg = `${accountMaxFileLimit}. ${$nuxt.$t('upgrade_to_convert_bigger_file')}`

        if (this.info.upload_limit && this.info.upload_limit.includes(',')) {
            msg = `${accountMaxFileLimit} for ${this.info.header_h1}. ${$nuxt.$t('upgrade_to_convert_bigger_file')}`
        }
        if (this.store.state.subscription.plan.isMaxSupportedFile) {
            msg = `${accountMaxFileLimit}. ${$nuxt.$t('largest_file_size_fc')}`
        }

        this.context.$gtm.push({ event: 'file_too_big' });
        this.store.commit('banner/showMaxFileSizeError', { size: formatSize(this.file.total), text: msg });

        this.context.$gtm.push({
            event: "max_file_size_limit",
            page: `${this.info.slug}-upload`,
            content: "redirect",
        });
        sentTelemetryEvent('file_size_limitation_reached', { file_size: formatSize(this.file.total), file_format: this.file.ext })

        localStorage.setItem("purchase_trigger", "max_file_size_limit");
        localStorage.setItem("purchase_slug", `${this.info.slug}-upload`);

        this.router.push(this.localePath('/pricing'));
    }

    handleConversionMinuteExceeded() { }

    handleConcurrentConversionExceeded() { }

    handleConvertionMinuteForFileExceeded() { }

    handleMergeLimitExceeded(limit) {
        this.store.commit('banner/showMergeLimitExceededError', { limit });

        this.context.$gtm.push({
            event: "merge_limit",
            page: "upload-page",
            content: "redirect",
        });
        this.router.push(this.localePath('/pricing'));
    }

    checkMergePrivilege() {
        if (!$nuxt.$store.state.advancedSetting.merging) return true
        const maxMergeLimit = $nuxt.$store.state.subscription?.maxMergeLimit
        if (!maxMergeLimit) return true
        const numberOfFiles = $nuxt.$store.state.list?.length
        return numberOfFiles <= maxMergeLimit
    }

    validateMergePrivilege() {
        this.checkMergePrivilege()
            ? $nuxt.$store.commit('banner/hideMergeLimitExceededError')
            : $nuxt.$store.commit('banner/showMergeLimitExceededError')
    }
}

export default UserPrivilegesValidator;
