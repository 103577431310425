import HttpService from '../HttpService'

/**
 * Resumes failed task of given job_id
 * @param {*} job_id 
 * @returns 
 */
export async function resume(job_id) {
    try {
        const data = await HttpService.process()
            .setPath(`/jobs/retry/${job_id}`)
            .setToken($nuxt.$auth.Token)
            .get();
        return data;
    } catch (e) {
        console.log('resume error',e)
    }
}

export async function fetchTaskDetails(task_id) {
    try {
        const {data} = await HttpService.process()
            .setPath(`/tasks/${task_id}`)
            .setToken($nuxt.$auth.Token)
            .get();
        return data;
    } catch (e) {
        console.log('fetch error',e)
    }
}
